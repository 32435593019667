<template>
  <div :data-intercom-target="formSchema.dataIntercomTarget.layout">
    <!-- Form Header -->
    <v-card
      color="pglightblue"
      :data-intercom-target="formSchema.dataIntercomTarget.header"
    >
      <v-card-title class="justify-left tw-text-white">
        <p class="tw-text-white tw-font-bold">
          {{ formSchema.title }} -
        </p>
        <p
          v-if="patientProfile.PatientID"
          class="tw-justify-center tw-content-center tw-text-white tw-text-base tw-font-semibold"
        >
          {{ patientProfile['FIRST NAME'] }} {{ patientProfile['LAST NAME'] }}
        </p>
      </v-card-title>
    </v-card>

    <!-- Stepper -->
    <v-stepper
      v-model="activeStep"
      non-linear
      class="custom-header"
      :data-intercom-target="formSchema.dataIntercomTarget.stepper"
    >
      <!-- Stepper headers -->
      <v-stepper-header>
        <v-stepper-step
          :complete="activeStep > 1"
          step="1"
          :data-intercom-target="formSchema.dataIntercomTarget.stepperTabs[0]"
        >
          <div class="d-flex align-center">
            <span class="text--primary text-4xl font-weight-bold me-3">01</span>
            <div class="d-flex flex-column">
              <span class="text--primary text-sm font-weight-semibold">{{ formSchema.stepperHeader[0].title }}</span>
              <span class="text--secondary text-xs">{{ formSchema.stepperHeader[0].substitle }}</span>
            </div>
          </div>
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step
          step="2"
          :data-intercom-target="formSchema.dataIntercomTarget.stepperTabs[1]"
        >
          <div class="d-flex align-center">
            <span class="text--primary text-4xl font-weight-bold me-3">02</span>
            <div class="d-flex flex-column">
              <span class="text--primary text-sm font-weight-semibold">{{ formSchema.stepperHeader[1].title }}</span>
              <span class="text--secondary text-xs">{{ formSchema.stepperHeader[1].substitle }}</span>
            </div>
          </div>
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step
          step="3"
          :data-intercom-target="formSchema.dataIntercomTarget.stepperTabs[2]"
        >
          <div class="d-flex align-center">
            <span class="text--primary text-4xl font-weight-bold me-3">03</span>
            <div class="d-flex flex-column">
              <span class="text--primary text-sm font-weight-semibold">{{ formSchema.stepperHeader[2].title }}</span>
              <span class="text--secondary text-xs">{{ formSchema.stepperHeader[2].substitle }}</span>
            </div>
          </div>
        </v-stepper-step>
      </v-stepper-header>

      <!-- Stepper Items -->
      <v-stepper-items>
        <!-- Stepper Item #1 -->
        <v-stepper-content
          step="1"
          :data-intercom-target="formSchema.dataIntercomTarget.stepperTabContent[0]"
        >
          <v-card
            v-if="formSchema.MedTableStatus"
            :key="componentKeyMedList"
          >
            <componenet
              :is="formSchema.MedTableName"
              :selected="selectedMedication"
              :data-intercom-target="formSchema.dataIntercomTarget.drugTable"
              @medicationupdatelist="selected"
            ></componenet>
          </v-card>

          <v-card
            v-if="formSchema.DoctorTableStatus"
            :key="componentKeyDoctorTable"
          >
            <componenet
              :is="formSchema.DoctorTableOptions[formSchema.DoctorTableName]"
              :selected-doc="selectedDoctor"
              :data-intercom-target="formSchema.dataIntercomTarget.doctorTable"
              @doctorupdatelist="selectedDoc"
            ></componenet>
          </v-card>
        </v-stepper-content>

        <!-- Stepper Item #2 -->
        <v-stepper-content
          step="2"
          :data-intercom-target="formSchema.dataIntercomTarget.stepperTabContent[1]"
        >
          <div class=" tw-grid tw-grid-cols-1 lg:tw-grid-cols-3">
            <div class=" md:tw-t-0 md:tw-col-span-2">
              <div
                v-for="(schema,index) in schemas"
                :key="schema.index"
              >
                <div class=" tw-m-2 tw-shadow tw-overflow-hidden sm:tw-rounded-md">
                  <div class="tw-px-4 tw-py-5 tw-bg-white sm:tw-p-6 dark:tw-bg-darkmode">
                    <p :class="schema[0].titleClass">
                      {{ index+1 }}. {{ schema[0].title }}
                    </p>
                    <FormulateForm
                      :key="formulateFormKey"
                      v-model="prePopulate"
                      :data-intercom-target="formSchema.dataIntercomTarget.formFields[index]"
                      :schema="schema"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div
              :key="componentKeySideCards"
              class="tw-mt-2 "
            >
              <div
                v-if="formSchema.patientDetails"
                class="d-flex justify-center align-center"
                :data-intercom-target="formSchema.dataIntercomTarget.infoCards[0]"
              >
                <div class="tw-w-11/12 tw-card tw-shadow-2xl lg:tw-card-side tw-bg-pglightblue tw-text-primary-content">
                  <div class="tw-card-body">
                    <div class="tw-grid tw-grid-cols-1 tw-gap-4 tw-justify-items-start tw-items-start">
                      <div class="tw-card-title tw-font-black">
                        Patient
                      </div>
                    </div>
                    <div
                      v-if="this.prePopulate.patientlastname"
                      class="tw-grid tw-grid-cols-2 tw-gap-2 tw-justify-items-start tw-items-start"
                    >
                      <div>{{ this.prePopulate.patientlastname }}, {{ this.prePopulate.patientfirstname }}</div>
                      <div>Gender: {{ this.prePopulate.gender }}</div>
                      <div>DOB: {{ this.prePopulate.dob }}</div>
                      <div>OHIP: {{ this.prePopulate.ohip }}</div>
                      <div>{{ this.acceptNumber(this.prePopulate.ptTel) }}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                v-if="formSchema.doctorDetails"
                class="tw-pt-6 d-flex justify-center align-center"
                :data-intercom-target="formSchema.dataIntercomTarget.infoCards[1]"
              >
                <div class="tw-w-11/12 tw-card tw-shadow-2xl lg:tw-card-side tw-bg-pglightblue tw-text-primary-content">
                  <div class="tw-card-body">
                    <div class="tw-grid tw-grid-cols-1 tw-gap-4 tw-justify-items-start tw-items-start">
                      <div class="tw-card-title tw-font-black">
                        Doctor
                      </div>
                    </div>
                    <div
                      v-if="this.selectedDoctor[0]"
                      class="tw-grid tw-grid-cols-2 tw-gap-2 tw-justify-items-start tw-items-start"
                    >
                      <div>Dr. {{ this.selectedDoctor[0].DoctorFirstName }} {{ this.selectedDoctor[0].DoctorLastName }}</div>
                      <div>CPSO: {{ this.selectedDoctor[0].CPSO }}</div>
                      <div>Tel: {{ this.acceptNumber(this.selectedDoctor[0].DoctorPhone) }}</div>
                      <div>Fax: {{ this.acceptNumber(this.selectedDoctor[0].DoctorFax) }}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                v-if="formSchema.PrescriptionDetails"
                :data-intercom-target="formSchema.dataIntercomTarget.infoCards[2]"
                class="tw-pt-6 d-flex justify-center align-center"
              >
                <div class="tw-w-11/12 tw-card tw-shadow-2xl lg:tw-card-side tw-bg-pglightblue tw-text-primary-content">
                  <div
                    v-if="this.selectedMedication.length > 0"
                    class="tw-card-body"
                  >
                    <div
                      v-for="(selectedMed, index) in selectedMedication"
                      :key="selectedMed.RxNumber"
                    >
                      <div class="tw-grid tw-grid-cols-1 tw-justify-items-start tw-items-start">
                        <p class="tw-font-black tw-text-gray-900	">
                          Medication {{ index + 1 }}:
                        </p>
                        <div><span class="tw-font-black tw-pt-3">{{ selectedMed.BrandName }} ({{ selectedMed.GenericName }}) {{ selectedMed.RxStrength }} {{ selectedMed.Form }}</span> </div>
                        <div>Rx #: {{ selectedMed.RxNumber }}</div>
                        <div>Rx Date: {{ $moment(selectedMed.RxDate).format('YYYY-MM-DD') }}</div>
                        Instructions:
                        <div>
                          <span class="tw-text-sm tw-font-black tw-pt-3">
                            {{ selectedMed.SIGFull }}</span>
                        </div>
                        <div class="tw-grid tw-grid-cols-2 tw-gap-2 tw-justify-items-start tw-items-start tw-pt-6">
                          <div>Quantity:<span class="tw-text-sm tw-font-black tw-pt-3"> {{ selectedMed.QtyDispense }} </span></div>
                          <div>Days Supply: <span class="tw-text-sm tw-font-black tw-pt-3">{{ selectedMed.Days }}</span></div>
                          <div>
                            Total Authorized:<span class="tw-text-sm tw-font-black tw-pt-3">
                              {{ selectedMed.TotalAuthorized }}
                            </span>
                          </div>
                          <div>Quantity Remaining:<span class="tw-text-sm tw-font-black tw-pt-3"> {{ selectedMed.QtyRemain }} </span></div>
                        </div>
                        <div class="tw-grid tw-grid-cols-1 tw-gap-2 tw-justify-items-start tw-items-start tw-pt-6">
                          <div>Prescriber: <span class="tw-text-sm tw-font-black tw-pt-3">Dr. {{ selectedMed.FirstName }} {{ selectedMed.LastName }}</span></div>
                        </div>
                        <div class="tw-divider"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </v-stepper-content>

        <!-- Stepper Item #3 -->
        <v-stepper-content
          step="3"
          :data-intercom-target="formSchema.dataIntercomTarget.stepperTabContent[2]"
        >
          <v-expansion-panels v-model="finalpanel">
            <v-expansion-panel v-if="formSchema.pharmacistSignatureStatus">
              <v-expansion-panel-header>
                <span class="text-base">Pharmacist Signature <v-icon
                  v-if="this.PharmacistSignature===''"
                  color="warning"
                  medium
                >
                  {{ icons.mdiAlert }}
                </v-icon></span>
              </v-expansion-panel-header>

              <v-expansion-panel-content :data-intercom-target="formSchema.dataIntercomTarget.pharmacistSignature">
                <div>
                  <v-radio-group
                    v-model="pharmacistSignatureOption"
                    column
                  >
                    <v-radio
                      label="E-Signature"
                      value="esign"
                    ></v-radio>
                    <v-radio
                      label="Signature Pad"
                      value="pad"
                    ></v-radio>
                  </v-radio-group>
                </div>
                <div
                  v-if="pharmacistSignatureOption === 'esign'"
                  class="tw-justify-center tw-align-center"
                >
                  <div class="tw-grid tw-grid-cols-3 tw-gap-4 tw-bg-transparent tw-justify-center">
                    <div class="  tw-bg-transparent tw-flex tw-justify-center">
                      <pharmacist-search :key="refresh"></pharmacist-search>
                      <v-text-field
                        v-model="patientProfile['rphfirstname']"
                        class=""
                        label="Pharmacist name"
                        suffix=""
                        outlined
                        :append-icon="icons.mdiArrowExpand"
                        @click:append="openPharmacistOverlay"
                      >
                        hide-details
                        >
                      </v-text-field>
                    </div>
                  </div>
                  <div
                    v-if="patientProfile['rphfirstname']"
                    class="tw-grid tw-justify-items-stretch"
                  >
                    <div
                      id="signatureTextTyped"
                      class="tw-place-items-center tw-justify-self-auto tw-content-center tw-h-64 tw-grid tw-grid-rows-1 tw-mt-12 tw-bg-transparent  tw-justify-center"
                    >
                      <p
                        id="signatureTextFont"
                        class="tw-capitalize tw-px-8 tw-mx-8 tw-pb-10 tw-mb-6 tw-text-center tw-bg-transparent tw-italic tw-font-serif tw-text-5xl tw-text-black"
                      >
                        {{ this.capitalizeFirstLetter(patientProfile['rphfirstname']) }}
                      </p>
                    </div>
                  </div>
                  <div class=" tw-bg-transparent tw-flex tw-justify-center">
                    <button
                      id="signatureButton"
                      class="tw-primary tw-btn"
                      :class="{ 'tw-loading':loadingSignature }"
                      @click="injectRphSignature"
                    >
                      Inject Pharmacist E-Signature Signature
                    </button>
                  </div>
                  <v-alert
                    v-if="this.injected"
                    color="primary"
                    dark
                  >
                    Injected Successfully.
                  </v-alert>
                </div>
                <div v-if="pharmacistSignatureOption === 'pad'">
                  <div class="Signaturecontainer">
                    <div class="Signaturecontainer">
                      <div
                        v-show="!initial"
                        class="d-flex justify-center align-center"
                      >
                        <VueSignaturePad
                          id="signature"
                          :key="refreshSignatureStatus"
                          ref="signaturePad"
                          class="border-solid border-4 border-black"
                          width="700px"
                          height="200px"
                          :options="{ onBegin, onEnd }"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="buttons">
                    <v-btn
                      color="primary"
                      @click="undoPharmacistSignature"
                    >
                      Undo
                    </v-btn>
                  </div>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel v-if="formSchema.patientSignatureStatus">
              <v-expansion-panel-header>
                <span class="text-base">Patient Signature <v-icon
                  v-if="this.PatientSignature===''"
                  color="warning"
                  medium
                >
                  {{ icons.mdiAlert }}
                </v-icon></span>
              </v-expansion-panel-header>

              <v-expansion-panel-content :data-intercom-target="formSchema.dataIntercomTarget.patientSignature">
                <div>
                  <v-radio-group
                    v-model="verbalConsent"
                    column
                  >
                    <v-radio
                      label="Click here to sign as COVID-19 Verbal Consent"
                      value="Yes"
                      @click="injectCovidConsent"
                    ></v-radio>
                    <v-radio
                      label="Click here to sign a Typed Signature"
                      value="No"
                      @click="changeNameCase"
                    ></v-radio>
                  </v-radio-group>
                </div>
                <div
                  class="tw-justify-center tw-align-center"
                >
                  <div class="tw-grid tw-grid-cols-3 tw-gap-4 tw-bg-transparent tw-justify-center">
                    <div class="  tw-bg-transparent tw-flex tw-justify-center">
                      <v-text-field
                        v-model="patientFullName"
                        class=""
                        label="Typed Signature"
                        suffix=""
                        outlined

                        hide-details
                      >
                      </v-text-field>
                    </div>
                  </div>
                  <div class="tw-grid tw-justify-items-stretch">
                    <div
                      id="signatureTextTyped"
                      class="tw-place-items-center tw-justify-self-auto tw-content-center tw-h-64 tw-grid tw-grid-rows-1 tw-mt-12 tw-bg-transparent  tw-justify-center"
                    >
                      <p
                        id="signatureTextFont"
                        class="tw-px-8 tw-mx-8 tw-pb-10 tw-mb-6 tw-text-center tw-bg-transparent tw-italic tw-font-serif tw-text-5xl tw-text-black"
                      >
                        {{ patientFullName }}
                      </p>
                    </div>
                  </div>
                  <div class=" tw-bg-transparent tw-flex tw-justify-center">
                    <button
                      id="signatureButton"
                      class="tw-primary tw-btn"
                      :class="{ 'tw-loading':loadingSignature }"
                      @click="printPartOfPage"
                    >
                      Inject Typed Patient Signature
                    </button>
                  </div>
                  <v-alert
                    v-if="this.injected"
                    color="primary"
                    dark
                  >
                    Injected Successfully.
                  </v-alert>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
              <v-expansion-panel-header>
                <span class="text-base">Pharmacist Information: {{ patientProfile['rphfirstname'] }} </span>
              </v-expansion-panel-header>
              <v-expansion-panel-content :data-intercom-target="formSchema.dataIntercomTarget.pharmacistInfo">
                <v-container>
                  <v-row
                    wrap
                    ml-4
                  >
                    <v-col
                      cols="auto"
                      xs="12"
                      sm="12"
                      md="4"
                    >
                      <pharmacist-search :key="refresh"></pharmacist-search>
                      <v-btn
                        id="v-step-5"
                        color="primary"
                        primary
                        class="my-3"
                        @click.native="openPharmacistOverlay"
                      >
                        Select a Pharmacist
                      </v-btn>
                    </v-col>
                    <v-col
                      cols="auto"
                      xs="12"
                      sm="6"
                      md="3"
                    >
                      <v-text-field
                        v-model="patientProfile['rphfirstname']"
                        label="Pharmacist Name"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="auto"
                      xs="12"
                      sm="6"
                      md="3"
                    >
                      <v-text-field
                        v-model="patientProfile['ocp']"
                        label="OCP#"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-stepper-content>
      </v-stepper-items>

      <!-- Buttons -->
      <div class="d-flex justify-center align-center">
        <v-card-actions>
          <v-col>
            <v-btn
              v-show="activeStep === 1 && ((this.selectedMedication.length > 0 && this.selectedDoctor.length > 0) || (this.selectedMedication.length > 0 && formSchema.DoctorTableStatus === false) || (this.selectedDoctor.length > 0 && formSchema.MedTableStatus === false))"
              :data-intercom-target="formSchema.dataIntercomTarget.nextButton1enabled"
              color="success"
              large
              @click="nextstepone"
            >
              Next
            </v-btn>
            <v-btn
              v-show="activeStep === 1 && ((this.selectedMedication.length === 0 && this.selectedDoctor.length === 0) || (this.selectedMedication.length === 0 && formSchema.DoctorTableStatus === false) || (this.selectedDoctor.length === 0 && formSchema.MedTableStatus === false) || (this.selectedDoctor.length === 0 && this.selectedMedication.length > 0) || (this.selectedDoctor.length > 0 && this.selectedMedication.length === 0 && formSchema.DoctorTableStatus === true && formSchema.MedTableStatus === true) || (this.selectedDoctor.length === 0 && this.selectedMedication.length > 0 && formSchema.DoctorTableStatus === true && formSchema.MedTableStatus === true))"
              :data-intercom-target="formSchema.dataIntercomTarget.nextButton1disabled"
              color="success"
              large
              disabled
            >
              Next
            </v-btn>
          </v-col>
          <v-col>
            <v-btn
              v-show="activeStep === 2"
              :data-intercom-target="formSchema.dataIntercomTarget.previousButton1"
              color="warning"
              large
              @click="activeStep = 1"
            >
              Previous
            </v-btn>
          </v-col>
          <v-col>
            <v-btn
              v-show="activeStep === 3"
              :data-intercom-target="formSchema.dataIntercomTarget.previousButton2"
              color="warning"
              large
              @click="activeStep = 2"
            >
              Previous
            </v-btn>
          </v-col>
          <v-col>
            <v-btn
              v-show="activeStep === 2"
              :data-intercom-target="formSchema.dataIntercomTarget.nextButton2"
              color="success"
              large
              @click="nextstep"
            >
              Next
            </v-btn>
          </v-col>
          <v-col>
            <pg-submit
              v-show="activeStep === 3"
              id="v-step-7"
              :data-intercom-target="formSchema.dataIntercomTarget.submitButton"
              :form-type="formSchema.formClass"
              :base-filename="formSchema.formClass"
              type-of-medscheck="typeOfMedscheck"
              :patient-data-decorator="ProcessMeds"
              :patient-data="prePopulate"
              @saveInfo="savePdfInfo($event)"
            >
              Submit
            </pg-submit>
          </v-col>
        </v-card-actions>
      </div>
    </v-stepper>

    <!-- Dialog -->
    <v-dialog
      v-model="loadingPatient"
      hide-overlay
      persistent
      width="300"
    >
      <v-card
        :data-intercom-target="formSchema.dataIntercomTarget.standByDialog"
        color="primary"
        dark
      >
        <v-card-text class="pt-3">
          Please stand by
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import {
  mdiAlert,
  mdiCheckboxMarkedCircleOutline,
  mdiArrowExpand,
} from '@mdi/js'
import { UltimateTextToImage } from 'ultimate-text-to-image'

// import { generate } from 'text-to-image'
import { formSchema } from '@/assets/formSchema/templateSchema'
import PharmacistSearch from '@/components/common/ui/Form/PharmacistSearch/PharmacistSearch.vue'
import submitPdf from '@/components/common/ui/Form/Submit/ProcessPDF.vue'
import serverFormProcess from '@/views/Medscheck/Utils/processFormDataMedicationSelection'
import { stateService } from '@/render/api/zarya'

import singleDrug from '@/components/phoxTables/drug/singleDrug.vue'
import multiDrug from '@/components/phoxTables/drug/multiDrug.vue'
import multiDrugMax3 from '@/components/phoxTables/drug/multiDrugMax3.vue'
import multiDrugRepeat from '@/components/phoxTables/drug/multiDrugRepeat.vue'
import doctorTable from '@/components/phoxTables/doctor/doctorTable.vue'
import signatureCovid from '@/views/Medscheck/sigCovid.png'
import signatureConsent from '@/views/Medscheck/sigConsent.png'
import SignaturePad from '@/components/common/ui/Form/SignaturePad/SignaturePad.vue'

export default {
  name: 'FormTemplate',
  components: {
    'pg-submit': submitPdf,
    'pharmacist-search': PharmacistSearch,
    singleDrug,
    multiDrug,
    multiDrugMax3,
    multiDrugRepeat,
    doctorTable,
    SignaturePad,
  },

  data() {
    this.$store.dispatch('setFormClass', formSchema.formClass)

    return {
      // Signatures
      signatureCovid,
      signatureConsent,
      injected: false,
      pharmacistSignatureOption: 'esign',

      // Button Conditionals
      disabled: false,

      // loading Statuses
      loadingPatient: false,
      loadingSignature: false,

      // panels & steps
      activeStep: 1, // 1 default
      finalpanel: 0, // 0 default
      render: 'Yes',

      // patient Data
      patientProfile: {},
      prePopulate: {},
      selectedMedication: [],
      selectedDoctor: [],
      selectedMed: [],
      rxDetails: '',
      dateOfTransaction: '', // check
      dateTimeofTransaction: '', // check
      PharmacistSignature: '',
      PatientSignature: '',
      verbalConsent: 'No',
      patientFullName: '',
      imgData: '',

      // schemas
      formSchema,
      schemas: [],

      // keys
      componentKeySideCards: 2,
      componentKeyMedList: 1,
      componentKeyDoctorTable: 0,
      formulateFormKey: 0,
      refreshSignatureStatus: 0, // check
      refreshPatientSignatureStatus: 0, // check
      refresh: 0, // check

      // Signature
      initial: false,

      // icons
      icons: {
        mdiAlert,
        mdiCheckboxMarkedCircleOutline,
        mdiArrowExpand,
      },
    }
  },
  computed: {
    ...mapGetters([
      'FormClass',
      'PatientProfile',
      'PatientMedications',
      'PharmacyProfile',
      'UserProfile',
    ]),
    ...mapState(['Patient']),
  },
  mounted() {
    this.changeNameCase()
    this.preloadForm()
    this.renderSignaturePad()
    this.saveDateSubmitted()
    this.currentDate()

    // this.printPartOfPage()
    this.$root.$on('Refresh_Table', patientID => {
      this.changeNameCase()
      this.selectedPatient()
      this.currentDate()
      this.saveDateSubmitted()
      this.renderSignaturePad()
      this.preloadForm()
      this.step = '1'
      this.finalpanel = 0

      // this.printPartOfPage()
    })
  },
  methods: {
    // The above code is injecting a patientFullName variable into the Vue instance.
    injectCovidConsent() {
      this.patientFullName = 'COVID-19: Verbal Consent'
      this.printPartOfPage()
    },

    async printPartOfPage() {
      const textToImage = new UltimateTextToImage(`${this.patientFullName}`, {
        fontFamily: 'Robertson',
        fontSize: 80,
        fontStyle: 'italic',
      }).render()
      const dataUrlPng = textToImage.toDataUrl()
      console.log(dataUrlPng)
      this.PatientSignature = dataUrlPng
      this.patientProfile.ptsignature1 = dataUrlPng
      this.prePopulate.ptsignature1 = dataUrlPng
      this.injected = true
      setTimeout(() => {
        this.injected = false
      }, 1500)
    },
    async injectRphSignature() {
      const textToImage = new UltimateTextToImage(
        `${this.capitalizeFirstLetter(this.patientProfile.rphfirstname)}`,
        { fontFamily: 'Robertson', fontSize: 80, fontStyle: 'italic' },
      ).render()
      const dataUrlPng = textToImage.toDataUrl()
      console.log(dataUrlPng)
      this.PharmacistSignature = dataUrlPng
      this.patientProfile.PharmacistSignature = dataUrlPng
      this.prePopulate.rphsignature = dataUrlPng
      this.injected = true
      setTimeout(() => {
        this.injected = false
      }, 1500)
    },
    changeNameCase() {
      if (this.PatientProfile['FIRST NAME'] != null) {
        this.patientFullName = `${this.capitalizeFirstLetter(
          this.PatientProfile['FIRST NAME'],
        )} ${this.capitalizeFirstLetter(this.PatientProfile['LAST NAME'])}`
      }
    },
    capitalizeFirstLetter(str) {
      str = str.toLowerCase()

      return str.charAt(0).toUpperCase() + str.slice(1)
    },
    preloadForm() {
      this.patientData = {
        ...this.$store.state.Patient.data,
        PharmacistSignature: '',
      }
      this.medicationsList = this.$store.state.Patient.medications
      this.patientProfile = {
        ...this.$store.state.Patient.data,
      }
    },
    selectedPatient() {
      this.prePopulate = {}
      this.activeStep = 1
      this.selectedMedication = []
      this.componentKeyMedList += 1
      this.componentKeySideCards += 1
      this.componentKeyDoctorTable += 1
    },

    // The `acceptNumber` function accepts a string value (phone number XXXXXXXXXX) and returns a formatted string value(phone number XXX-XXX-XXXX).
    acceptNumber(value) {
      const x = value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/)
      const formattedValue = !x[2]
        ? x[1]
        : `(${x[1]}) ${x[2]}${x[3] ? `-${x[3]}` : ''}`

      return formattedValue
    },
    currentDate() {
      const current = new Date()
      const date = `${current.getFullYear()}/${
        current.getMonth() + 1
      }/${current.getDate()}`
      this.patientProfile.DATE = date
      this.refresh += 1

      return date
    },

    selected(selectedMedication) {
      this.selectedMedication = selectedMedication
      this.patientProfile = {
        ...this.patientProfile,
        rphfirstname: `${this.$store.state.User.user.firstName} ${this.$store.state.User.user.lastName}`,
        ocp: this.$store.state.User.user.ocp,
        selectedMedication: this.selectedMedication,
      }
    },
    selectedDoc(selectedDoctor) {
      this.selectedDoctor = selectedDoctor
      this.patientProfile = {
        ...this.patientProfile,
        selectedDoctor: this.selectedDoctor,
      }
    },
    async populateWithPatientData() {
      this.prePopulate = {
        ...this.patientProfile,
        patientlastname: this.PatientProfile['LAST NAME'],
        patientfirstname: this.PatientProfile['FIRST NAME'],
        dob: this.$moment(this.PatientProfile['DATE OF BIRTH']).format(
          'YYYY-MM-DD',
        ),
        'dob[year]': this.$moment(this.PatientProfile['DATE OF BIRTH']).format(
          'YYYY',
        ),
        'dob[month]': this.$moment(this.PatientProfile['DATE OF BIRTH']).format(
          'MM',
        ),
        'dob[day]': this.$moment(this.PatientProfile['DATE OF BIRTH']).format(
          'DD',
        ),
        ohip: this.PatientProfile['HEALTH CARD NUMBER'],
        versionCode: '',
        gender: this.PatientProfile.GENDER,
        ptAddress: `${this.PatientProfile['STREET NUMBER']} ${this.PatientProfile['STREET NAME']}`,
        ptCity: this.PatientProfile['CITY/TOWN'],
        ptPostalCode: this.PatientProfile['POSTAL CODE'],
        ptTel:
          this.PatientProfile['TELEPHONE NUMBER'] ||
          this.PatientProfile['CELLPHONE NUMBER'],
        pharmacyName: this.PatientProfile['PHARMACY NAME'],
        pharmacyStreetAdd: `${this.PatientProfile['PHARMACY UNIT NUMBER']}-${this.PatientProfile['PHARMACY STREET NUMBER']} ${this.PatientProfile['PHARMACY STREET NAME']}, ${this.PatientProfile['PHARMACY CITY']}`,
        pharmacyTelephoneNumber:
          this.PatientProfile['PHARMACY TELEPHONE NUMBER'],
        pharmacyFaxNumber: this.PatientProfile['PHARMACY FAX NUMBER'],
        pharmacyProvince: this.PatientProfile['PHARMACY PROVINCE'],
        pharmacyPostal: this.PatientProfile['POSTAL CODE'],
        pharmacistName: `${this.UserProfile.firstName} ${this.UserProfile.lastName}`,
        ocp: this.UserProfile.ocp,
        patientForm: 'yes',
        needsAdditionalDrugTherapy: 'On',
        pharmacyID: this.$store.getters.PharmacyProfile._id,
        userID: this.$store.getters.UserProfile._id,
        pharmacyFullAdd: `${this.$store.state.User.pharmacy.address.streetNumber} ${this.$store.state.User.pharmacy.address.streetName}, ${this.$store.state.User.pharmacy.address.city} ${this.$store.state.User.pharmacy.address.state} ${this.$store.state.User.pharmacy.address.code}`,
        patientID: this.$store.getters.PatientProfile.PatientID,
        mfax: '16473610914@send.mfax.io',
        dateOfTransaction: this.dateOfTransaction,
        drFaxDateTime: this.dateTimeofTransaction,

        // rxDetails: `Original Prescription ${this.selectedMedication[0].BrandName} ${this.selectedMedication[0].RxStrength}`,
        drName: `${this.selectedDoctor[0].DoctorFirstName} ${this.selectedDoctor[0].DoctorLastName}`,
        drFax: this.acceptNumber(this.selectedDoctor[0].DoctorFax),
        cpso: this.selectedDoctor[0].CPSO,
        drTel: this.acceptNumber(this.selectedDoctor[0].DoctorPhone),
        rphsignature: this.PharmacistSignature,
        ptsignature1: this.PatientSignature,

        // RxNumber: this.selectedMedication[0].RxNumber,
        // LastName: this.selectedMedication[0].LastName,
        // FirstName: this.selectedMedication[0].FirstName,
        // CPSO: this.selectedMedication[0].CPSO,
        // DoctorPhone: this.selectedMedication[0].DoctorPhone,
        // DoctorFax: this.selectedMedication[0].DoctorFax,
        // RxDate: this.$moment(this.selectedMedication[0].RxDate).format('YYYY-MM-DD'),
        // DIN: this.selectedMedication[0].DIN,
        // GenericName: this.selectedMedication[0].GenericName,
        // RxStrength: this.selectedMedication[0].RxStrength,
        // Form: this.selectedMedication[0].Form,
        // Schedule: this.selectedMedication[0].Schedule,
        // BrandName: this.selectedMedication[0].BrandName,
        // RxQtyDispense: this.selectedMedication[0].RxQtyDispense,
        // QtyRemain: this.selectedMedication[0].QtyRemain,
        // SIGFull: this.selectedMedication[0].SIGFull,
        // QtyDispense: this.selectedMedication[0].QtyDispense,
        // Days: this.selectedMedication[0].Days,
        // TotalAuthorized: this.selectedMedication[0].TotalAuthorized,
      }
      this.schemas = []
      for (let i = 0; i < this.formSchema.schemas.length; i++) {
        this.schemas.push(this.formSchema.schemas[i])
        console.log(this.formSchema.schemas[i][0].id)
      }
      this.loadingPatient = false
    },

    savePdfInfo(data) {
      this.prePopulate.pdfRef = {
        ...data,
      }
      this.updateStateStatus()
    },
    saveDateSubmitted() {
      const current = new Date()
      const date = `${current.getFullYear()}/${
        current.getMonth() + 1
      }/${current.getDate()}`
      const time = `${current.getHours()}:${current.getMinutes()}:${current.getSeconds()}`
      const dateTime = `${date} ${time}`
      this.patientProfile.dateSubmitted = dateTime
      this.dateOfTransaction = date
      this.dateTimeofTransaction = dateTime
    },
    async updateStateStatus() {
      this.saveDateSubmitted()
      let res
      const data = {
        formType: this.$store.state.Patient.FORM_CLASS,
        state: this.prePopulate,
        patientID: this.patientProfile.PatientID,
        userID: this.UserProfile._id,
        pharmacyID: this.PharmacyProfile._id,
      }

      // this.$router.push({
      //   name: 'dashboard-document',
      //   query: {
      //     tab: this.formSchema.documentsDashboardTab,
      //   },
      // })
      const token = await this.$auth.getTokenSilently()
      try {
        if (!this.patientProfile.stateId) {
          // create it and make its status complete (this is in case they complete a medscheck and it doesnt have a stateid already -- not working )
          // eslint-disable-next-line no-undef
          res = await stateService.createState(token, {
            ...data,
          })
          this.patientProfile.stateId = res.state._id
        }
        res = await stateService.updateState(
          token,
          this.patientProfile.stateId,
          {
            ...data,
            status: 'complete',
          },
        )

        return res
      } catch (err) {
        console.log(err)
      }

      return res
    },
    nextstepone() {
      this.populateWithPatientData()
      this.medicationsForPDF(this.patientData)
      this.activeStep = 2
      this.refreshSignatureStatus += 1
      this.refreshPatientSignatureStatus += 1
    },
    nextstep() {
      this.activeStep = 3
      this.refreshSignatureStatus += 1
      this.refreshPatientSignatureStatus += 1
    },
    ProcessMeds(patientData) {
      return serverFormProcess(
        this.selectedMedication,
        this.medicationsForPDF(patientData),
      )
    },
    medicationsForPDF(patientData) {
      this.selectedMedication.forEach((med, idx) => {
        patientData = {
          ...patientData,
          [`rxNumbers${idx + 1}`]: `${med['RX NUMBER'] || med.RxNumber} - ${
            med['GENERIC NAME'] || med.GenericName
          } - ${med.STRENGTH || med.RxStrength} - Qty: ${
            med.RxQtyDispense
          } - Qty Auth:${med.TotalAuthorized}`,
        }
      })
      this.rxDetails = patientData

      return patientData
    },
    openPharmacistOverlay() {
      window.searchPharmacist.open(({ PharmID, UserName }) => {
        this.patientProfile = Object.assign(this.patientProfile, {
          rphfirstname: UserName,
          ocp: PharmID,
        })
      })
    },

    onEnd() {
      console.log('end')
      if (this.refreshSignatureStatus === 0) {
        this.refreshSignatureStatus += 1
      }
      this.savePharmacistSignature()
      this.canvaslocal = this.$refs.signaturePad.$refs.signaturePadCanvas
    },
    savePharmacistSignature() {
      console.log('this is active')
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature()
      this.patientProfile.PharmacistSignature = data
      this.prePopulate.rphsignature = data
      this.PharmacistSignature = data
    },
    undoPharmacistSignature() {
      this.$refs.signaturePad.undoSignature()
    },

    hideSignaturePanel() {
      this.signatureStatus = false
    },

    onBegin() {},
    renderSignaturePad() {
      // This is for pre-populating the signature pad

      // if (this.patientProfile.PharmacistSignature) {
      //   this.initial = true
      // } else {
      //   this.initial = false
      // }
      this.initial = false
    },
  },
}
</script>

<style lang="scss" scoped>
#signateCovidBox {
  width: 400px;
  height: 200px;
}

#signatureTextFont {
  font-family: 'Robertson';
  font-size: 60px;
}

#signature {
  border: double 3px transparent;
  border-radius: 5px;
  background-image: linear-gradient(white, white),
    radial-gradient(circle at top left, #000000, #000000);
  background-origin: border-box;
  background-clip: content-box, border-box;
  justify-content: center !important;
  align-content: center !important;
}

#signaturePatient {
  border: double 3px transparent;
  border-radius: 5px;
  background-image: linear-gradient(white, white),
    radial-gradient(circle at top left, #000000, #000000);
  background-origin: border-box;
  background-clip: content-box, border-box;
  justify-content: center !important;
  align-content: center !important;
}

//signature Button
.buttons {
  display: flex;
  gap: 8px;
  justify-content: center;
  margin-top: 8px;
}

.Signaturecontainer {
  width: '100%';
  padding: 8px 16px;
}

.SignaturecontainerPat {
  width: '100%';
  padding: 8px 16px;
}
</style>
