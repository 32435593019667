/* eslint-disable indent */
/* The formSchema object is a dictionary of the form template.

The formSchema object has a title property that is the title of the form.

The formSchema object has a stepperHeader property that is an array of objects.

Each object in the stepperHeader array has a step property that is the step number.

Each object in the stepperHeader array has a title property that is the title of the step.

Each object in the stepperHeader array has a substitle property that is the subtitle */

export const patientInfo = [{
    id: 'patientInfo',
    title: 'Patient Information',
    titleClass: 'tw-text-2xl tw-mb-4',
    component: 'div',
    class: 'tw-grid tw-grid-cols-3 tw-gap-6',
    children: [{
            type: 'text',
            label: 'Patient First Name',
            name: 'patientfirstname',
            key: 'patientfirstname',
        },
        {
            type: 'text',
            label: 'Patient Last Name',
            name: 'patientlastname',
            key: 'patientlastname',
        },
        {
            type: 'text',
            label: 'Patient Date of Birth',
            name: 'dob',
            key: 'dob',
        },
    ],
}]

export const drInfoSchema = [{
        id: 'drInfo',
        title: 'Doctor Information',
        titleClass: 'tw-text-2xl tw-mb-4',
        component: 'h3',
    },
    {
        component: 'div',
        class: 'tw-grid tw-grid-cols-3 tw-gap-6',
        children: [{
                type: 'text',
                label: 'Name',
                name: 'drName',
            },
            {
                type: 'text',
                label: 'ID Number',
                name: 'cpso',
            },
            {
                type: 'text',
                label: 'Prescriber’s One-Mail Email Address (If available from presecriber)',
                name: 'drEmail',
            },
        ],
    },
    {
        component: 'div',
        class: 'tw-grid tw-grid-cols-3 tw-gap-6',
        children: [{
                type: 'text',
                label: 'Facsimile',
                name: 'drFax',
            },
            {
                type: 'text',
                label: 'Office Telephone',
                name: 'drTel',
            },
            {
                type: 'text',
                label: 'Date/Time of Transmission to Prescriber:',
                name: 'drFaxDateTime',
            },
        ],
    },
]

export const callerInfo = [{
    id: 'callerInfo',
    title: 'Caller Information',
    titleClass: 'tw-text-2xl tw-mb-4',
    component: 'div',
    class: 'tw-grid tw-grid-cols-3 tw-gap-6',
    children: [{
            type: 'date',
            label: 'Date',
            name: 'date',
            key: 'date',
        },

        {
            type: 'text',
            label: 'Caller of Verbal Prescription',
            name: 'callerofRx',
            key: 'callerofRx',
        },
        {
            type: 'text',
            label: 'Time of Call',
            name: 'time',
            key: 'time',
        },
    ],
}]

export const DRUG_TABLE_OPTIONS = {
    SINGLE_DRUG: 'single-drug',
    MULTI_DRUG: 'multi-drug',
    MULTI_DRUG_MAX_3: 'multi-drug-max-3',
    MULTI_DRUG_REPEAT: 'multi-drug-repeat',
    MULTI_DRUG_ACTIVE_ONLY: 'multi-drug-active-only',
}

//    MedTableName: DRUG_TABLE_OPTIONS.SINGLE_DRUG,
// CHAGNE FROM TRUE/FALSE TO IF IT EXISTS OR IF IT DOESNT

export const formSchema = {
    title: 'Emad Form', // formTemplate
    formClass: 'frequentDispensingAssessment',
    stepperHeader: [{
            step: '1',
            title: 'Medication',
            substitle: 'Select Med and Doctor',
        },
        {
            step: '2',
            title: 'Details',
            substitle: 'Lets Document the Details',
        },
        {
            step: '3',
            title: 'Form Signoff',
            substitle: 'Form Submission',
        },
    ],
    DoctorTableStatus: true,
    DoctorTableName: 'DoctorTable',
    DoctorTableOptions: {
        DoctorTable: 'doctor-table',
    },
    MedTableStatus: true,
    MedTableName: DRUG_TABLE_OPTIONS.MULTI_DRUG,
    dataIntercomTarget: {
        layout: 'formSchema',
        header: 'Header',
        stepper: 'Stepper',
        stepperTabs: ['stepper1', 'stepper2', 'stepper3'],
        stepperTabContent: ['stepper1Content', 'stepper2Content', 'stepper3Content'],
        drugTable: 'drugTable',
        doctorTable: 'doctorTable',
        formFields: ['patientInfo', 'drInfoSchema'],
        infoCards: ['patientDetails', 'doctorDetails', 'PrescriptionDetails'],
        pharmacistSignature: 'pharmacistSignature',
        patientSignature: 'patientSignature',
        pharmacistInfo: 'pharmacistInfo',
        submitButton: 'submitButton',
        nextButton1enabled: 'nextButton1enabled',
        nextButton1disabled: 'nextButton1disabled',
        previousButton1: 'previousButton1',
        nextButton2: 'nextButton2',
        previousButton2: 'previousButton2',
        standByDialog: 'standByDialog',
    },
    patientDetails: true,
    doctorDetails: true,
    PrescriptionDetails: true,
    schemas: [patientInfo, drInfoSchema, callerInfo],
    pharmacistSignatureStatus: true,
    patientSignatureStatus: true,
    documentsDashboardTab: 5,
}

export const formSchemaFields = []